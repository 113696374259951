module.exports = {
    "educational-leadership": {
        "title": "MA in Educational Leadership & Administration Program Guide",
        "thumbnail": "/assets/images/pdf-placeholder.jpg",
        "document": "/assets/docs/sample.pdf",
        "teaser": "Get more details about **admissions requirements**, **program highlights**, and your **next steps**."
    },
    "human-services": {
        "title": "AAS in Human Services Program Guide",
        "thumbnail": "/assets/images/thumbnails/human-services.jpg",
        "document": "/assets/docs/Chatfield College AAS in Human Services.pdf",
        "teaser": "Get more details about **admissions requirements**, the **courses you’ll take**, and your **next steps**."
    },
    "liberal-arts": {
        "title": "AA in Liberal Arts Program Guide",
        "thumbnail": "/assets/images/thumbnails/liberal-arts.jpg",
        "document": "/assets/docs/Chatfield College AA in Liberal Arts.pdf",
        "teaser": "Get more details about **admissions requirements**, the **courses you’ll take**, and your **next steps**."
    }
}